import { Guarded } from ":shared/components/route";
import { Show } from "solid-js";
import { Platform } from ":shared/components/loaders";
import routes from "./routes";
import authRoutes from "../auth/routes";
import { Loader1 } from ":src/components/loaders";

export default function Landing() {
  const baseRoute = routes.BASE;
  return (
    <Guarded
      setup={{
        baseRoute,
      }}
      components={{
        layout: (props) => {
          return null;
          // return (
          //   <Show
          //     when={props.guard.loading}
          //     fallback={
          //       <div class="flex flex-row w-full h-full">
          //         <div class="flex w-50% bg-red"></div>
          //         <div>{props.children}</div>
          //       </div>
          //     }
          //   >
          //     <Loader1 msg={props.guard.msg} />
          //   </Show>
          // );
        },
        pageNotFound: () => <div>this is landing page error, this page doesn't exist</div>,
      }}
      events={{
        onRouteFirstLoad(trigger, props) {
          trigger.redirect({ base: authRoutes.BASE });
        },
      }}
    ></Guarded>
  );
}
